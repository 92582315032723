// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  overflow: hidden;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
}

.swiper-vertical .swiper-wrapper {
  flex-direction: column;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
}

.swiper-initialized .swiper-slide {
  flex-shrink: 0;
}

.swiper-android .swiper-slide,
.swiper-android .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/scss/base/swiper.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AACA;EACC,WAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;AAED;;AACC;EACC,sBAAA;AAEF;;AAEC;EACC,uBAAA;AACF;;AAGC;EACC,cAAA;AAAF;;AAKC;;EAEC,iCAAA;AAFF;;AAKA;EACC,wBAAA;AAFD","sourcesContent":[".swiper {\r\n\toverflow: hidden;\r\n}\r\n.swiper-wrapper {\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tbox-sizing: content-box;\r\n\tdisplay: flex;\r\n\tposition: relative;\r\n}\r\n.swiper-vertical {\r\n\t.swiper-wrapper {\r\n\t\tflex-direction: column;\r\n\t}\r\n}\r\n.swiper-autoheight {\r\n\t.swiper-wrapper {\r\n\t\talign-items: flex-start;\r\n\t}\r\n}\r\n.swiper-initialized {\r\n\t.swiper-slide {\r\n\t\tflex-shrink: 0;\r\n\t\t// transition-property: transform;\r\n\t}\r\n}\r\n.swiper-android {\r\n\t.swiper-slide,\r\n\t.swiper-wrapper {\r\n\t\ttransform: translate3d(0px, 0, 0);\r\n\t}\r\n}\r\n.swiper-button-lock {\r\n\tdisplay: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
